
































import Vue from "vue";
import VueSocketIOExt from "vue-socket.io-extended";
import io from "socket.io-client";
import draggable from "vuedraggable";

export default Vue.use(VueSocketIOExt, io("https://circles-api.hyunwoo.dev/")).extend({
	components: {
		draggable,
	},
	sockets: {
		interview_getInterviewByClubName(this: any, data) {
			this.$store.commit("clearLoading", "interview_getInterviewByClubName");
			this.isStart = data.result;
			if (data.result) this.interviewers = data.data.interviewers;
		},
		interview_startInterview(this: any, data) {
			this.$store.commit("clearLoading", "interview_startInterview");
			this.isStart = data.result;
		},
		interview_closeInterview(this: any, data) {
			this.$store.commit("clearLoading", "interview_closeInterview");
			this.isStart = !data.result;
		},
		interview_updateInterviewers(this: any, data) {
			this.interviewers = data.data.interviewers;
		},
	},
	data() {
		return {
			applicants: [],
			interviewers: [],
			isStart: false,
		};
	},
	created() {
		this.$store.commit("pushLoading", {
			name: "interview_getInterviewByClubName",
			message: "면접 불러오는 중",
		});
		this.$socket.client.emit("interview_getInterviewByClubName", {
			clubname: this.getClub.name,
		});
		this.$store.commit("pushLoading", {
			name: "GET_CLUB_APPLICANT",
			message: "동아리 지원서 불러오는 중",
		});
		this.$store
			.dispatch("GET_CLUB_APPLICANT")
			.then((applicants) => {
				let _ids = this.interviewers.map((x: any) => x._id);
				this.$store.commit("clearLoading", "GET_CLUB_APPLICANT");
				this.applicants = applicants
					.map((x) => {
						return {
							name: x.name,
							number: x.number,
							_id: x._id,
						};
					})
					.filter((x) => _ids.indexOf(x._id) == -1);
			})
			.catch((err) => {});
	},
	methods: {
		start() {
			this.$store.commit("pushLoading", {
				name: "interview_startInterview",
				message: "면접 시작하는 중",
			});
			this.$socket.client.emit("interview_startInterview", {
				clubname: this.getClub.name,
				interviewers: this.interviewers,
			});
		},
		close() {
			this.$store.commit("pushLoading", {
				name: "interview_closeInterview",
				message: "면접 끝내는 중",
			});
			this.$socket.client.emit("interview_closeInterview", {
				clubname: this.getClub.name,
			});
		},
		update() {
			if (this.isStart) {
				this.$socket.client.emit("interview_updateInterviewers", {
					clubname: this.getClub.name,
					interviewers: this.interviewers,
				});
			}
		},
		interviewerClose(idx: number) {
			this.applicants.push(this.interviewers[idx]);
			this.interviewers.splice(idx, 1);
			this.update();
		},
	},
	computed: {
		getClub(): any {
			return this.$store.state.club;
		},
	},
});
